<template>
  <div>
    <footer class="g-footer bg-dark pad-t-4 pad-b-2">
      <div class="bb-container">
        <no-ssr>
          <div class="g-footer__cols">
            <mq-layout :mq="['m480', 'm576', 'm768']" class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.downloadApp", footerLang) }}</p>
                <CtaAppLinks :iconsLang="footerLang" />
              </div>
            </mq-layout>
            <div class="g-footer__col">
              <div class="l-footer">
                <div class="l-footer__title">{{ $t("footer.yourList", footerLang) }}</div>
                <ul class="l-footer__list">
                  <li><router-link :to="$t('routes.login', footerLang)" rel="nofollow">{{ $t("header.logIn", footerLang) }}</router-link></li>
                  <li>
                    <router-link :to="$t('routes.signup', footerLang)" rel="nofollow">{{ $t("signup.title", footerLang) }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col" v-if="featureBlogAvailable && footerLang != 'fr-FR' && footerLang !='it-IT'">
              <div class="l-footer">
                <p class="l-footer__title">Blog</p>
                <ul class="l-footer__list">
                  <li>
                    <a href="https://www.hellobb.net/blog/categorias/productos"
                    >Guías sobre productos</a
                    >
                  </li>
                  <li>
                    <a href="https://www.hellobb.net/blog/categorias/maternidad"
                    >Guías sobre maternidad</a
                    >
                  </li>
                  <li>
                    <a href="https://www.hellobb.net/blog/categorias/listas-de-ejemplo"
                    >Listas de ejemplo</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.listTypes", footerLang) }}</p>
                <ul class="l-footer__list">
                  <li>
                    <a :href='$t("routes.home", footerLang)'
                    >Wishlist</a
                    >
                  </li>
                  <li>
                    <a :href='$t("routes.babyRegistry", footerLang)'
                    >{{ $t("footer.babyRegistry", footerLang) }}</a
                    >
                  </li>
                  <li>
                    <a :href='$t("routes.landingWedding", footerLang)'
                    >{{ $t("footer.weddingList", footerLang) }}</a
                    >
                  </li>
                </ul>
                </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">HelloBB</p>
                <ul class="l-footer__list">
                  <li>
                    <a href="/hellobb-para-vendedores">
                      HelloBB para tu ecommerce
                    </a>
                  </li>
                  <li>
                    <a :href="$t('routes.aboutUs', footerLang)">
                      {{ $t("aboutUs.aboutUs", footerLang) }}
                    </a>
                  </li>
                  <li><a rel="nofollow" href="mailto:hola@hellobb.net">{{ $t("footer.contact", footerLang) }}</a></li>
                  <li>
                    <router-link to="/cookies" rel="nofollow"
                    >{{ $t("cookiesPolicy.cookiesPolicy", footerLang) }}</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="$t('routes.confidentialityPolicy', footerLang)" rel="nofollow">
                      {{ $t("privacyPolicy.privacyPolicy", footerLang) }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$t('routes.legalNotice', footerLang)" rel="nofollow">{{ $t("legalNotice.legalNotice", footerLang) }}</router-link>
                  </li>
                  <li>
                    <router-link :to="$t('routes.affiliation', footerLang)" rel="nofollow"
                    >{{ $t("affiliationPolicy.affiliationAndAdvertising", footerLang) }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.help", footerLang) }}</p>
                <ul class="l-footer__list">
                  <li>
                    <router-link :to="$t('routes.faq', footerLang)" rel="nofollow"
                    >{{ $t("faq.faq", footerLang) }}</router-link
                    >
                  </li>
                  <li v-if="featureEcommerceAvailable && footerLang != 'fr-FR' && footerLang !='it-IT'"><router-link to="/envios" rel="nofollow">Envíos</router-link></li>
                  <li v-if="featureEcommerceAvailable && footerLang != 'fr-FR' && footerLang !='it-IT'">
                    <router-link to="/devoluciones" rel="nofollow"
                    >Devoluciones</router-link
                    >
                  </li>
                  <!-- <li v-if="featureEcommerceAvailable && footerLang != 'fr-FR' && footerLang !='it-IT'">
                    <router-link to="/programa-de-recompensas" rel="nofollow"
                    >Programa de recompensas</router-link
                    >
                  </li> -->
                  <li v-if="featureWebMapAvailable && footerLang != 'fr-FR' && footerLang !='it-IT'">
                    <router-link to="/mapa-web">Mapa web</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.followUs", footerLang) }}</p>
                <div class="l-footer__social">
                  <LazyHydrate when-visible>
                    <FooterSocialIcons :iconsLang="footerLang" />
                  </LazyHydrate>
                </div>
              </div>
              <mq-layout :mq="['m992', 'm1200']" class="l-footer">
                <p class="l-footer__title">{{ $t("footer.downloadApp", footerLang) }}</p>
                <CtaAppLinks :iconsLang="footerLang" />
              </mq-layout>
            </div>
          </div>
        </no-ssr>
        <div class="g-footer__copy">© helloBB 2024 — {{ $t("footer.reservedRights", footerLang) }}</div>
      </div>
    </footer>
  </div>
</template>
<script>
import CtaAppLinks from "../modules/cta/CtaAppLinks.vue";
import FooterSocialIcons from "./components/FooterSocialIcons.vue";
import LazyHydrate from "vue-lazy-hydration";
import NoSSR from "vue-no-ssr";
import Features from "../Mixins/Features";

export default {
  name: "Footer",
  props: {
    footerLang: {
      type: String,
      default: "es-ES"
    }
  },
  components: {
    CtaAppLinks,
    FooterSocialIcons,
    LazyHydrate,
    "no-ssr": NoSSR,
  },
  mixins: [
    Features
  ],
};
</script>
